.header {
  height: 3rem;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  color: white;
  background: rgba(0, 0, 0, 0.5);
  z-index: 10;
  @media only screen and (max-width: "500px") {
    display: none;
  }

  ul {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-around;
    gap: 3rem;

    a {
      font-family: "Orbitron", sans-serif;
    }
  }
}

.link-wrapper {
  position: relative;
  display: block;
}
.link {
  cursor: pointer;
  display: inline-block;
  position: relative;
  padding: 4px 0;
  font-size: 1.2em;
  transition: 0.4s ease;

  @media only screen and (max-width: "640px") {
    grid-area: 2 / 2 / 3 / 5;
    padding: 0.5rem;
  }
}

.hover-1 {
  &:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 2px;
    bottom: 0;
    left: 0;
    background: white;
    transform: scaleX(0);
    transform-origin: bottom right;
    transition: transform 0.3s;
  }

  &:hover {
    &:after {
      transform-origin: bottom left;
      transform: scaleX(1);
    }
  }
}

.navigation {
  // Styles pour la navigation
}
