@import "./variables.scss";

.contact-container {
 
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 0;
  margin-bottom: 3rem;

  display: flex;
  flex-flow: row wrap;
  gap: 2em;
  align-items: flex-start;
  @media screen and (max-width: 1024px) {
    // align-items: center;
    padding-top: 6rem;
  }
}
.title-container {
  z-index: 9;
  height: fit-content;
  display: flex;
  @media screen and (max-width: 1024px) {
    justify-content: center;
  }
}

#contact {
  position: relative;
  background-image: url("../../public/contact_background2.png");
  // cover
  background-size: cover;
  // contain
  background-repeat: no-repeat;
}

.contact-content {
  display: flex;
  flex-flow: row wrap;
  gap: 3rem;
  @media screen and (max-width: 1024px) {
    justify-content: center;
    height: 100%;
  }

  // align-items: center;
}

.contact-photo img {
  min-width: $contact-buttons-min-width;
  max-width: 300px;
  border-radius: 14px;
}

.contact-buttons {
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 1024px) {
    justify-content: center;
  }
  gap: 1rem;
  .btn {
    background: rgba(0, 0, 0, 0.7);
  }
}

.btn-github,
.btn-malt,
.btn-linkedin {
  display: inline-block;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  text-decoration: none;
  //   border: 2px solid #ccc;
  border-radius: 4px;
  transition: all 0.3s ease;

  &:hover {
    background-color: $gradient-color;
    color: #fff;
  }
}

.btn-github i,
.btn-malt i,
.btn-linkedin i {
  margin-right: 1rem;
  font-size: 2rem;
}

.btn-github,
.btn-malt,
.btn-linkedin {
  display: flex;
  align-items: center;
}

.btn-malt {
  // Styles spécifiques au bouton Malt
}

.btn-linkedin {
  // Styles spécifiques au bouton LinkedIn
}

.mail {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5em;
  background: rgba(0, 0, 0, 0.7);
  border-radius: 4px;
  padding: 0.5em 0.5em;
  width: $contact-buttons-min-width;

  &:hover {
    background-color: $gradient-color;
    color: #fff;
  }

  p {
    font-family: "Orbitron", sans-serif;
  }
}
