.blur-bottom {
    width: 100%;
    bottom: 0;
    height: 16rem;
    position: absolute;
    background-image: linear-gradient(to bottom, rgba(255, 75, 244, 0), $gradient-color)
}
@import './variables';
.blur-top {
    width: 100%;
    top: 0;
    height: 16rem;
    position: absolute;
    background-image: linear-gradient(to top, rgba(255, 75, 244, 0), $gradient-color)
}