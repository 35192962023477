@import "./styles/Header";
@import "./styles/About";
@import "./styles/Projects";
@import "./styles/Contact";
@import "./styles/Blur";
@import "./styles/variables.scss";

:root {
  // dark scroll bar
  color-scheme: dark;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Montserrat", sans-serif;
}

// enleve le style des liste à puces
ul {
  list-style: none;
}
// eleve le style des liens
a {
  text-decoration: none;
  // eleve la couleur des liens cliqué (bleu par défaut)
  color: inherit;
}

// applique un pading left et right de 20% en rem
.container {
  padding: 0 $container-padding;
  @media screen and (max-width: 500px) {
    padding: 0 $container-padding-mobile;
  }

  display: flex;
  align-items: center;

  
  @media screen and (min-width: 1024px) {
    min-height: 100vh;
    @media screen and (max-height: 720px) {
      min-height: var(--window-height);
    }
  }
}

// Police pour les titres (header)
h1,
h2 {
  font-family: "Orbitron", sans-serif;
  font-size: 3em !important;
  @media screen and (max-width: 1024px) {
    font-size: 2em !important;
  }
}

h3 {
  font-size: $section-title-font-size;
  font-family: "Orbitron", sans-serif;
}

// Police pour le reste du texte
body {
  font-family: "Roboto Mono", monospace;
  color: rgb(255, 236, 251);
  .about-content {
    font-size: 1.25em;
  }
  @media screen and (max-width: 1024px) {
    .about-content {
      font-size: 1em;
    }
  }
}
