$gradient-pink: #420C41;
$gradient-blue: #31808b;
$gradient-color: $gradient-blue;

$card-width: 20rem;
$card-height: fit-content;

$container-padding: 20%;
$container-padding-mobile: 5%;
    


$contact-buttons-min-width: 16rem;

$section-title-font-size: 2.5rem;


