.about {
  .about-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2rem;
  }

  padding: 2rem 0;
  background-image: url("../../public/about_background.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  @media screen and (max-width: 720px) {
    height: var(--window-height);
  }

  color: rgb(227, 238, 255);

  .section-title {
    font-size: 3rem;
    font-weight: bold;
    margin-bottom: 1rem;
    width: 100%;
    margin-top: 3rem;
    @media screen and (max-width: 1024px) {
      text-align: center;
    }
  }

  .about-content {
    z-index: 9;
    line-height: 1.5;
    text-align: justify;
    background: rgba(0, 0, 0, 0.3);
    padding: 1rem;
    border-radius: 10px;
    width: 100%;
    height: 35%;
  }
}

.animated-text {
  white-space: pre-wrap;
}

.cursor {
  display: inline-block;
  position: relative;
}
.cursor::after {
  content: "";
  margin: auto;
  position: absolute;
  right: -4px;
  top: -3px;
  width: 1px;
  height: 100%;
  background-color: #ffffff;
  animation: cursor-animation 1.5s step-end infinite;
}
@keyframes cursor-animation {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
