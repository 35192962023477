@import "./variables";

.projects-container {
  display: flex;
  flex-direction: column;
}
.title-container {
  width: 100%;
  margin-bottom: 3rem;
}
.projects-grid {
  display: flex;
  justify-content: flex-start;
  flex-flow: row wrap;
  gap: 2rem !important;
  @media screen and (max-width: 1024px) {
    justify-content: center;
  }
}

.projects {
  // background-image: url("../../public/projects_background.png");
  background-color: $gradient-color;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 3rem;

  @media (max-width: 768px) {
    .projects-grid {
      gap: 0.5rem;
    }
  }
}

.project-link {
  // restaure underline on link
  text-decoration: underline !important;
  &:hover {
    text-decoration: none !important;
  }
}

.card-container {
  width: 320px;
  height: 425px;
  perspective: 800px;

  &:hover > .card {
    cursor: pointer;
    // transform: rotateY(180deg) rotateZ(180deg);
    transform: rotateY(180deg);
  }

  .card {
    height: 100%;
    width: 100%;
    position: relative;
    transition: transform 1500ms;
    transform-style: preserve-3d;
    background: rgba(0, 0, 0, 0.5);
    // border-radius: 6px 6px 0 0;
    border-radius: 6px;

    img {
      width: 100%;
      height: 250px;
      object-fit: cover;
      border-radius: 6px 6px 0 0;
    }
  }

  .front,
  .back {
    height: 100%;
    width: 100%;
    border-radius: 6px;
    box-shadow: 0 0 5px 2px rgba(50, 50, 50, 0.25);
    position: absolute;
    backface-visibility: hidden;
  }

  .front {
    .project-info {
      min-height: 10rem;
      h3 {
        font-size: 1.5em;
        margin: 0.5rem;
      }

      p {
        font-size: 1.2rem;
        @media screen and (max-width: 1024px) {
          font-size: 1em;
        }
        line-height: 1.4;
        overflow-y: hidden;
        padding: 0 0.5em;
      }
    }
  }

  :root {
    --mouse-x: 0;
    --mouse-y: 0;
  }

  .back {
    // transform: rotateY(180deg) rotateZ(180deg);
    transform: rotateY(180deg);
    display: flex;
    flex-direction: column;

    .spotlight {
      width: 100%;
      height: 100%;
      background-color: hsla(0, 0%, 0%, 0.9);

      background: rgb(255, 255, 255);
      background: radial-gradient(
        circle at var(--mouse-x) var(--mouse-y),
        rgba(255, 255, 255, 0.3) 0%,
        rgba(90, 9, 121, 0) 80%,
        rgba(255, 255, 255, 0) 100%
      );
    }

    .techno-img-container {
      display: flex;
      flex-direction: row;
      padding: 0.5rem;
      gap: 0.5rem;
      align-items: center;
      .techno-img {
        height: 4rem;
        width: 4rem;
      }
    }

    h3 {
      font-size: 1.5em;
      margin: 0.5rem;
    }

    .taches-container {
      padding: 0.5rem;

      .tache-line {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 0.5rem;
      }
      .arrow-img {
        height: 1rem;
        width: 1rem;
        margin-right: 0.5rem;
      }
    }

    p {
      font-size: 1.1rem;
      @media screen and (max-width: 1024px) {
        font-size: 0.8rem;
      }
      line-height: 1.4;
      overflow-y: hidden;
    }
  }
}
